const IMG_BASE = 'https://resources.landcoo.com'

export default {
  data () {
    return {
      dialHome: {
        color: 'blue',
        icon: 'mdi-home',
        name: 'Home',
      },
      dialQuestionnaire: {
        color: 'orange darken-2',
        icon: '',
        image: `${IMG_BASE}/31084253798989824.png`,
        name: '',
      },
      dialComplaints: {
        color: 'purple darken-2',
        icon: '',
        image: `${IMG_BASE}/31084256576929792.png`,
        name: '',
      },
      dialMaintain: {
        color: 'red darken-2',
        icon: '',
        image: `${IMG_BASE}/31084258672640000.png`,
        name: '',
      },
      dialReport: {
        color: 'indigo darken-1',
        icon: '',
        image: `${IMG_BASE}/31084260720771072.png`,
        name: '',
      },
      speedDial: {
        activator: {
          color: 'blue darken-2',
          normal: 'mdi-dots-vertical',
          active: 'mdi-dots-horizontal'
        },
        items: []
      }
    }
  },
  methods: {
    onSpeedDial (res) {
      this.$router.push({
        name: res.name
      })
    },
  }
}
