
import api from '@/api/co.api'
import app from '@/api/co.app'
import lib from '@/api/co.lib'
import survey from '@/api/co.survey'

export default {
  data () {
    return {
      psqLabels: {},
      psqParams: null,
      psqItems: [],
      psqPagination: {
        current: 1,
        length: 1,
        totalVisible: 7
      },
    }
  },
  methods: {
    onProblem (ev) {
      const item = ev.item
      this.toProblem(item)
    },
    toProblem (item) {
      const params = {
        questionnaireId: item.questionnaireId,
        type: item.type,
        title: item.title
      }

      const name = api.page.homeProblem.name
      lib.cookie.set(name, JSON.stringify(params))
      this.$router.push({ name })
    },
    changePagination (number) {
      const params = this.psqParams
      const data = params.data
      const limit = parseInt(data.limit, 10) || 9
      const index = parseInt(number, 10) || 1

      this.psqPagination.current = index
      data.offset = (index - 1) * limit
      params.reload = true
      api.http.getItems(params)
    },
    initPsqParams ({
      siteId = app.init.siteId,
      type = survey.comm.Types.QUESTIONNAIRE,
      status = 'publish',
      start = '',
      end = '',
      limit = '10'
    }) {
      const me = this
      const executing = function () {
      }

      const executed = function (res) {
        if (res.status) {
          const data = me.psqParams.data
          const pagination = me.psqPagination
          const limit = parseInt(data.limit) || 10
          pagination.length = Math.ceil(res.total / limit)
        }
      }

      this.psqLabels = survey.comm.getLabels(type)

      this.psqParams = survey.main.getParams({
        siteId,
        type,
        status,
        start,
        end,
        limit,
        caches: this.psqItems,
        executing,
        executed
      })
    },
    loadPsqs (reload = true) {
      this.psqParams.reload = reload
      api.http.getItems(this.psqParams)
    }
  }
}
