<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="psqcenter"
      space="48"
    >
      <v-container>
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="10"
          >
            <div class="d-flex flex-column">
              <land-gallery
                :items="psqItems"
                per-columns="2"
                ratio="2"
                back-color="grey lighten-5"
                @click="onProblem"
              />
              <div class="d-flex flex-row mt-4">
                <v-pagination
                  v-model="psqPagination.current"
                  :length="psqPagination.length"
                  :total-visible="psqPagination.totalVisible"
                  @input="changePagination"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
    <land-speed-dial
      :activator="speedDial.activator"
      :items="speedDial.items"
      @click="onSpeedDial"
    />
  </section>
</template>

<script>

  import mixHomePsqsBanner from '@/pages/mixins/home/mix.home.psqs.banner'
  import mixSurveyPsqs from '@/pages/mixins/survey/mix.survey.psqs'
  import mixSpeedDial from '@/pages/mixins/mix.speeddial'

  export default {
    metaInfo () {
      return {
        title: this.pageTitle
      }
    },
    mixins: [
      mixHomePsqsBanner,
      mixSurveyPsqs,
      mixSpeedDial
    ],
    data () {
      return {
        pageTitle: '问卷调查'
      }
    },
    created () {
      this.setCrumbs(null)

      this.speedDial.items.push(
        this.dialHome,
        this.dialComplaints,
        this.dialMaintain,
        this.dialReport
      )

      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      this.initPsqParams({})
      this.loadPsqs()
    }
  }
</script>
